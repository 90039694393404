import { ControlType, RenderTarget, withCSS } from "framer"
import { ComponentType, forwardRef, useEffect, useState, useRef } from "react"
import { motion } from "framer-motion"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"

// totalPages
// pageHistory
// logicConfig
// isEndPage
// previousPage()
// nextPage()
export const useMultiStepFormStore = createStore({})

export const HIDDEN_CLASS_NAME = "framerforms-hidden"
const CANVAS_HIDDEN_CLASS_NAME = "framerforms-canvas-hidden"

export const createId = () =>
    "a" + String(Math.floor(Math.random() * 999999999))

export const HiddenInput = forwardRef(
    (
        {
            name,
            required,
            value,
            setInvalid,
            onSetValueEvent,
            noPrefill = false,
            disabled = false,
        },
        parentRef
    ) => {
        const internalRef = useRef(null)
        const ref = parentRef || internalRef

        useEffect(() => {
            if (ref.current) {
                ref.current.addEventListener(
                    "framerFormsSetValue",
                    onSetValueEvent
                )
            }

            return () => {
                if (ref.current) {
                    ref.current.removeEventListener(
                        "framerFormsSetValue",
                        onSetValueEvent
                    )
                }
            }
        }, [onSetValueEvent])

        return (
            <input
                ref={ref}
                type="text"
                data-framerforms-set-value-event={!!onSetValueEvent}
                data-framerforms-no-prefill={noPrefill}
                style={{
                    position: "absolute",
                    opacity: 0,
                    pointerEvents: "none",
                    inset: 0,
                }}
                tabIndex={-1}
                name={name}
                required={required}
                value={value}
                disabled={disabled}
                onInvalid={(event) => {
                    event.preventDefault()
                    setInvalid(true)
                }}
            />
        )
    }
)

const HideElementComponent = forwardRef(({}, ref) => {
    return <div ref={ref} className={HIDDEN_CLASS_NAME} />
})

export const HideElement: ComponentType = withCSS(
    HideElementComponent,
    [
        `div:has(> .${HIDDEN_CLASS_NAME}) { display: none; }`,
        `.${HIDDEN_CLASS_NAME} { display: none; }`,
    ],
    HIDDEN_CLASS_NAME
) as typeof HideElementComponent

HideElement.displayName = "FramerForms/Hide Element"

export const HiddenComponentLabel = forwardRef(
    ({ text, subtext = "", children = null, ...otherProps }, ref) => {
        const isCanvas = RenderTarget.current() === RenderTarget.canvas
        const id = createId()

        return isCanvas ? (
            <div
                {...otherProps}
                ref={ref}
                className={CANVAS_HIDDEN_CLASS_NAME}
                style={{
                    backgroundColor: "rgba(187, 187, 187, 0.15)",
                    borderRadius: 10,
                    padding: "12px 16px",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: 12,
                    color: "#999",
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                    alignItems: "center",
                    textAlign: "center",
                    width: "100%",
                    height: "100%",
                    textWrap: "nowrap",
                }}
            >
                {children}
                {text}
                {subtext && (
                    <span
                        style={{
                            opacity: 0.7,
                            whiteSpace: "pre",
                            lineHeight: 1.4,
                        }}
                    >
                        {subtext}
                    </span>
                )}
                <Border
                    width="1px"
                    style="solid"
                    borderColor="rgba(136, 136, 136, 0.1)"
                />
                <style>{`
                [data-framer-component-container="true"] div:has(> .${CANVAS_HIDDEN_CLASS_NAME}) {
                    display: none !important;
                }`}</style>
            </div>
        ) : (
            <div {...otherProps} ref={ref} className={HIDDEN_CLASS_NAME}>
                <HideElement />
                {children}
            </div>
        )
    }
)

HiddenComponentLabel.displayName = "FramerForms/Hidden Component Label"

export function iconProp({
    size = 16,
    buttonTitle = "Icon",
    controls = {},
} = {}) {
    return {
        type: ControlType.Object,
        buttonTitle,
        optional: true,
        defaultValue: {
            color: "#999",
            size,
        },
        controls: {
            image: {
                type: ControlType.Image,
            },
            color: {
                type: ControlType.Color,
                optional: true,
                defaultValue: "#999",
            },
            size: {
                type: ControlType.Number,
                defaultValue: size,
                min: 1,
                step: 1,
                displayStepper: true,
            },
            ...controls,
        },
    }
}

export function Icon({ image, color, size, style = {}, defaultImage }) {
    return (
        <div
            style={{
                width: size,
                height: size,
                minWidth: size,
                minHeight: size,
                pointerEvents: "none",
                backgroundColor: color,
                backgroundImage: color
                    ? "none"
                    : image
                      ? `url('${image}')`
                      : defaultImage,
                maskImage: image ? `url('${image}')` : defaultImage,
                backgroundSize: "contain",
                backgroundPosition: "center",
                maskSize: "contain",
                maskPosition: "center",
                ...style,
            }}
        ></div>
    )
}

Icon.displayName = "FramerForms/Icon"

export function Border({
    width,
    style,
    transition = null,
    borderColor = "inherit",
    opacity = 1,
}) {
    return (
        <motion.div
            animate={{ borderColor, opacity }}
            style={{
                position: "absolute",
                inset: 0,
                borderWidth: width,
                borderStyle: style,
                borderRadius: "inherit",
                pointerEvents: "none",
            }}
            initial={false}
            transition={transition}
        />
    )
}

Border.displayName = "FramerForms/Border"

export function createBackground(
    fill,
    on = null,
    focused = null,
    invalid = null
) {
    if (fill) {
        const onOff = typeof on == "boolean"

        if (fill.type == "color") {
            let color =
                (invalid && fill.colorInvalid) ||
                (focused && fill.colorFocus) ||
                (onOff ? (on ? fill.colorOn : fill.colorOff) : fill.color)
            return { backgroundColor: color, backgroundImage: "none" }
        } else {
            const colorA =
                (invalid && fill.colorAInvalid) ||
                (focused && fill.colorAFocus) ||
                (onOff ? (on ? fill.colorAOn : fill.colorAOff) : fill.colorA)
            const colorB =
                (invalid && fill.colorBInvalid) ||
                (focused && fill.colorBFocus) ||
                (onOff ? (on ? fill.colorBOn : fill.colorBOff) : fill.colorB)

            return {
                backgroundColor: "none",
                backgroundImage: `linear-gradient(${fill.gradientAngle}deg, ${colorB}, ${colorA})`,
            }
        }
    }

    return {}
}

export function parsePadding(padding) {
    if (typeof padding !== "string") {
        return [0, 0, 0, 0]
    }

    const values = padding.split(" ")
    const parsedValues = values.map((value) =>
        parseInt(value.replace("px", ""), 10)
    )

    switch (parsedValues.length) {
        case 1:
            const p = parsedValues[0]
            return [p, p, p, p]
        case 4:
            return parsedValues
    }

    return [0, 0, 0, 0]
}

export function fillProp({
    onOff = false,
    focus = true,
    invalid = true,
    optional = false,
    hidden = null,
    color = "rgba(187, 187, 187, 0.15)",
    colorA = "#BBB",
    colorB = "#676767",
    colorOn = "rgba(70, 160, 219, 0.2)",
    colorAOn = "#66C2FF",
    colorBOn = "#0099FF",
    colorOff = "rgba(187, 187, 187, 0.15)",
    colorAOff = "#BBB",
    colorBOff = "#676767",
} = {}) {
    return {
        type: ControlType.Object,
        optional,
        hidden,
        icon: "color",
        defaultValue: {
            type: "color",
            ...(onOff ? { colorOn, colorOff } : { color, colorA, colorB }),
        },
        controls: {
            type: {
                type: ControlType.Enum,
                defaultValue: "color",
                options: ["color", "linearGradient"],
                optionTitles: ["Color", "Gradient"],
                displaySegmentedControl: true,
            },
            ...(onOff
                ? {
                      colorOn: {
                          type: ControlType.Color,
                          defaultValue: colorOn,
                          hidden: (props) => props.type !== "color",
                      },
                      colorOff: {
                          type: ControlType.Color,
                          defaultValue: colorOff,
                          hidden: (props) => props.type !== "color",
                      },
                  }
                : {
                      color: {
                          type: ControlType.Color,
                          defaultValue: color,
                          hidden: (props) => props.type !== "color",
                      },
                  }),
            ...(focus
                ? {
                      colorFocus: {
                          type: ControlType.Color,
                          optional: true,
                          title: "Focus",
                          hidden: (props) => props.type !== "color",
                      },
                  }
                : {}),
            ...(invalid
                ? {
                      colorInvalid: {
                          type: ControlType.Color,
                          optional: true,
                          title: "Invalid",
                          hidden: (props) => props.type !== "color",
                      },
                  }
                : {}),
            ...(onOff
                ? {
                      colorAOn: {
                          type: ControlType.Color,
                          defaultValue: colorAOn,
                          title: "Colors On",
                          hidden: (props) => props.type !== "linearGradient",
                      },
                      colorBOn: {
                          type: ControlType.Color,
                          defaultValue: colorBOn,
                          title: " ",
                          hidden: (props) => props.type !== "linearGradient",
                      },
                      colorAOff: {
                          type: ControlType.Color,
                          defaultValue: colorAOff,
                          title: "Colors Off",
                          hidden: (props) => props.type !== "linearGradient",
                      },
                      colorBOff: {
                          type: ControlType.Color,
                          defaultValue: colorBOff,
                          title: " ",
                          hidden: (props) => props.type !== "linearGradient",
                      },
                  }
                : {
                      colorA: {
                          type: ControlType.Color,
                          defaultValue: colorA,
                          title: "Colors",
                          hidden: (props) => props.type !== "linearGradient",
                      },
                      colorB: {
                          type: ControlType.Color,
                          defaultValue: colorB,
                          title: " ",
                          hidden: (props) => props.type !== "linearGradient",
                      },
                  }),
            ...(focus
                ? {
                      colorAFocus: {
                          type: ControlType.Color,
                          optional: true,
                          title: "Focus",
                          hidden: (props) => props.type !== "linearGradient",
                      },
                      colorBFocus: {
                          type: ControlType.Color,
                          optional: true,
                          title: " ",
                          hidden: (props) => props.type !== "linearGradient",
                      },
                  }
                : {}),
            ...(invalid
                ? {
                      colorAInvalid: {
                          type: ControlType.Color,
                          optional: true,
                          title: "Invalid",
                          hidden: (props) => props.type !== "linearGradient",
                      },
                      colorBInvalid: {
                          type: ControlType.Color,
                          optional: true,
                          title: " ",
                          hidden: (props) => props.type !== "linearGradient",
                      },
                  }
                : {}),
            gradientAngle: {
                type: ControlType.Number,
                defaultValue: 0,
                title: "Angle",
                step: 1,
                min: 0,
                max: 360,
                unit: "°",
                hidden: (props) => props.type != "linearGradient",
            },
        },
    }
}

export function borderProp({
    onOff = false,
    focus = true,
    invalid = true,
    defaultValue = true,
    color = "rgba(136, 136, 136, 0.1)",
    colorOn = "#0099FF",
    colorOff = "rgba(136, 136, 136, 0.1)",
    colorFocus = "#0099FF",
    colorInvalid = "#FF5A6F",
    hidden = null,
} = {}) {
    return {
        type: ControlType.Object,
        optional: true,
        hidden,
        defaultValue: defaultValue
            ? {
                  color,
                  colorOn,
                  colorOff,
                  colorFocus,
                  colorInvalid,
                  width: "1px",
              }
            : undefined,
        controls: {
            ...(onOff
                ? {
                      colorOn: {
                          type: ControlType.Color,
                          defaultValue: colorOn,
                      },
                      colorOff: {
                          type: ControlType.Color,
                          defaultValue: colorOff,
                      },
                  }
                : {
                      color: {
                          type: ControlType.Color,
                          defaultValue: color,
                      },
                  }),
            ...(focus
                ? {
                      colorFocus: {
                          type: ControlType.Color,
                          defaultValue: colorFocus,
                          optional: true,
                          title: "Focus",
                      },
                  }
                : {}),
            ...(invalid
                ? {
                      colorInvalid: {
                          type: ControlType.Color,
                          defaultValue: colorInvalid,
                          optional: true,
                          title: "Invalid",
                      },
                  }
                : {}),
            width: {
                type: ControlType.Padding,
                defaultValue: "1px",
            },
            style: {
                type: ControlType.Enum,
                defaultValue: "solid",
                options: ["solid", "dashed", "dotted", "double"],
                optionTitles: ["Solid", "Dashed", "Dotted", "Double"],
            },
        },
    }
}

export function shadowsProp({ invalid = true, onOff = false } = {}) {
    return {
        type: ControlType.Object,
        buttonTitle: "Shadows",
        optional: true,
        controls: {
            ...(onOff
                ? {
                      on: {
                          type: ControlType.BoxShadow,
                          defaultValue:
                              "rgba(0, 153, 255, 0.5) 0px 2px 4px 0px",
                      },
                      off: {
                          type: ControlType.BoxShadow,
                          defaultValue: "rgba(0, 0, 0, 0.25) 0px 2px 4px 0px",
                      },
                  }
                : {
                      default: {
                          type: ControlType.BoxShadow,
                          defaultValue: "rgba(0, 0, 0, 0.25) 0px 2px 4px 0px",
                      },
                  }),
            focus: {
                type: ControlType.BoxShadow,
            },
            invalid: invalid
                ? {
                      type: ControlType.BoxShadow,
                  }
                : null,
        },
    }
}

export function useCanvasState(ref) {
    const [totalPages, setTotalPages] = useState(1)

    useEffect(() => {
        const element = ref.current

        let pages = null

        function updateState() {
            setTotalPages(pages ? pages.children.length : 1)
        }

        if (element) {
            const multiStepForms = document.querySelectorAll(
                "div[data-framerforms-multi-step-form]"
            )

            for (const multiStepForm of multiStepForms) {
                const form =
                    multiStepForm.closest("form") ||
                    multiStepForm.parentElement.parentElement.parentElement
                if (form && isAncestor(form, element)) {
                    pages = form.querySelector(
                        ':scope > [data-framer-name="Pages" i]'
                    )
                }
            }
        }

        updateState()

        if (pages) {
            const observer = new MutationObserver((mutationsList, observer) => {
                for (let mutation of mutationsList) {
                    if (mutation.type === "childList") {
                        updateState()
                    }
                }
            })

            observer.observe(pages, { childList: true })

            return () => observer.disconnect()
        }
    }, [])

    return { page: 0, totalPages }
}

export function isAncestor(ancestorElement, descendantElement) {
    if (!ancestorElement || !descendantElement) {
        return false
    }

    let currentElement = descendantElement
    while (currentElement !== null) {
        if (currentElement === ancestorElement) {
            return true
        }
        currentElement = currentElement.parentElement
    }
    return false
}

export function getFormInfo(element) {
    const form = element?.closest("form")

    let formId = null
    let pages = null

    if (form) {
        formId = form.getAttribute("framerforms-id")
        pages = form.querySelector(':scope > [data-framer-name="Pages" i]')

        if (!formId) {
            formId = uuid()
            form.setAttribute("framerforms-id", formId)
        }
    }

    return { form, formId, pages }
}

function uuid() {
    let d = new Date().getTime() // Timestamp
    let d2 = (performance && performance.now && performance.now() * 1000) || 0 // Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
            let r = Math.random() * 16 // random number between 0 and 16
            if (d > 0) {
                // Use timestamp until depleted
                r = (d + r) % 16 | 0
                d = Math.floor(d / 16)
            } else {
                // Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0
                d2 = Math.floor(d2 / 16)
            }
            return (c === "x" ? r : (r & 0x3) | 0x8).toString(16)
        }
    )
}
